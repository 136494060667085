var site = site || {};
site.geo = site.geo || {};

site.geo.locate = function (success_handler, error_handler) {
  // able to get location
  var success_handler_wrapper = function(position) {
    var lat;
    var lng;

    lat = position.coords.latitude;
    lng = position.coords.longitude;

    site.geo.hasCurrentLocation = true;
    site.geo.currentLocationData = { latitude: lat, longitude: lng };

    if (success_handler) {
      success_handler({
        lat: lat,
        lng: lng
      });
    }
  };

  // unable to get location info
  var error_handler_wrapper = function() {
    if (error_handler) {
      error_handler();
    }
  };

  if ( window.blackberry && blackberry.location.GPSSupported ) {
    if ( typeof(blackberry.location.setAidMode) == 'undefined' ) {
      error_handler();
      return;
    }

    blackberry.location.setAidMode(2);

    var getCurrentPosition = function(successCallback,errorCallback,options) {
      var bb_blackberryTimeout_id;

      var bb_timeout = function handleBlackBerryLocationTimeout() {
        clearTimeout(bb_blackberryTimeout_id);
        errorCallback();
      };

      bb_blackberryTimeout_id = setTimeout( bb_timeout, 1000 );

      var handleBlackBerryLocation = function() {
        var lat = blackberry.location.latitude;
        var lng = blackberry.location.longitude;
        if (!lat && !lng) {
          errorCallback();
        } else {
          var pos = {};
          pos.coords = {
            latitude: lat,
            longitude: lng
          };

          successCallback(pos);
        }
      };

      blackberry.location.onLocationUpdate(handleBlackBerryLocation);
      blackberry.location.refreshLocation();
    };

    getCurrentPosition(success_handler, error_handler);
  } else {
    navigator.geolocation.getCurrentPosition(success_handler_wrapper, error_handler_wrapper);
  }    
};
